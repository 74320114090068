import { navigate } from "gatsby"
import React, { useEffect } from "react"

import SEO from "../components/SEO"
import episodes from "../constants/episodes"

type Props = {
  pageContext: {
    episodeIndex: number
  }
}

const Episode: React.FC<Props> = ({ pageContext }: Props) => {
  const episode = episodes[pageContext?.episodeIndex || 0]

  const title = episode.title
  const description = episode.metaDescription ?? episode.description
  const image = episode.image
  useEffect(() => {
    const time = episode.time
    navigate("/", { state: { time, title, description, image } })
  }, [description, episode, image, title])
  return (
    <>
      <SEO image={image} title={title} description={description} />
      <h1></h1>
    </>
  )
}

export default Episode
